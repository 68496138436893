<template>

  <div id="my">
    <mt-header fixed title="产检信息建档"></mt-header>
    <div class="content">

      <van-tabs v-model="selected">
        <van-tab id="tab-jbxx" title="基本信息">
          <van-form>
            <van-cell-group>
              <van-field v-model="jbxx.ZJLX_W" colon label="证件类型" required readonly clearable
                         @click="zjlxclick('zjlx')" placeholder="请选择证件类型"/>
              <van-field v-model="jbxx.SFZH" colon label="证件号" required clearable placeholder="请输入证件号码"/>
              <van-field v-model="jbxx.XM" colon label="孕妇姓名" required clearable placeholder="请输入姓名"/>
              <van-field v-model="jbxx.CSRQ" colon required clearable label="出生日期" placeholder="请输入出生日期(格式:yyyy-MM-dd)"
                         :rules="rules.csrq"/>
              <van-field v-model="jbxx.SJH" colon required :rules="rules.tel" type="tel" clearable label="手机号"
                         placeholder="请输入手机号"/>
              <van-field v-model="jbxx.MZ" colon @click="zjlxclick('mz')" clearable label="民族" placeholder="请选择民族"/>
              <van-field v-model="jbxx.XL" colon @click="zjlxclick('xl')" clearable label="文化程度" placeholder="请选择学历"/>
              <van-field v-model="jbxx.ZY" colon @click="zjlxclick('zy')" clearable label="职业" placeholder="请选择职业"
              />
              <van-field v-model="jbxx.GZDW" colon clearable label="工作单位" :rules="rules.gzdw" placeholder="请输入工作单位"/>

              <van-cell title="户口性质" required>
                <template>
                  <van-radio-group v-model="jbxx.HKXZ" direction="horizontal">
                    <van-radio name="1">城镇</van-radio>
                    <van-radio name="2">农村</van-radio>
                  </van-radio-group>
                </template>
              </van-cell>
              <van-field

                  v-model="jbxx.XZZ"
                  is-link
                  readonly
                  label="现住址"
                  placeholder="请选择现住址"
                  @click="xzzselectshow = true"
                   required  colon
              />
              <van-field v-model="jbxx.XZZXX"   label=" "  clearable placeholder="详细地址(街道、小区、门牌号)"/>

              <van-popup v-model="xzzselectshow" round position="bottom">
                <van-cascader
                    v-model="jbxx.XZZ"
                    title="请选择现住址"
                    :options="xzzselectdatas"
                    @close="xzzselectshow = false"
                    @change="onXzzChange"
                    @finish="onXzzFinish"
                />
              </van-popup>
            </van-cell-group>

            <van-action-sheet v-model="sheetVisible" :actions="selectlist.zjlxlist" @select="zjlxlistSelected"/>
          </van-form>
        </van-tab>
        <van-tab id="tab-zfxx" title="丈夫信息">丈夫信息</van-tab>
        <van-tab id='tab-jkxx' title="健康信息">健康信息</van-tab>
        <van-tab id='tab-jbs'> title="疾病史">疾病史</van-tab>
      </van-tabs>


    </div>
  </div>
</template>
<script>
import {
  nation, profession, education
  /*,maritalStatus,*/, zjlx
} from "@/common/Constants"
import {Toast} from "vant";
import {Indicator} from "mint-ui";
// import DialogUtil from "@/common/DialogUtil";
export default {
  name: "cjinfocreate",
  data() {
    return {
      xzzselectshow: false,
      idcard: '',
      tel:'', companyID: '',
      jbxx: {//基本信息
        ZJLXDM_W: ''/*证件类型代码*/, ZJLX_W: '居民身份证'/*证件类型*/,
        SFZH: ''/*身份证号*/, XM: ''/*姓名*/,
        CSRQ: ''/*出生日期*/, SJH: ''/*手机号*/, MZ: ''/*民族*/, ZY: ''/*职业*/
        ,XL: ''/*学历*/, GZDW: ''/*工作单位*/, HKXZ: '1'
        ,XZZDM: ''/*现住址代码*/,XZZ: ''/*现住址*/,XZZXX:''/*现住址详情*/ //现住址
        ,HJDDM: ''/*户籍地代码*/,HJDZ:''/*户籍地址*/,HJDZXX:''/*户籍地址信息*///户籍地址、户籍地址详情
        ,XYDDM: ''/**/,CHXYDZ:'',XYDXX:''//修养地
      },
      zfxx:{//丈夫信息
        ZFZJLXDM:'',ZFZJLX:'',ZFSFZH:''//丈夫证件类型、丈夫身份证号
        ,ZFXM:'',ZFNL:'',ZFSJH:''//丈夫姓名、丈夫年龄、丈夫手机号、
        ,ZFHJDDM:'', ZFHJDZ:'',ZFHJDZXX:'' //丈夫户籍地址、丈夫户籍地址详情
        ,ZFMZ:'', ZFXL:'', ZFZY:'',  ZFGZDW:'' //丈夫民族、丈夫学历、丈夫职业、丈夫工作单位
      },
      jkxx:{//健康信息
        MCYJ:''/*末次月经*/, YCQ:''/*预产期*/, YJZQ:''/*月经周期*/, YJJQ:''/*月经经期*/
        ,QZFS:''/*确诊方法*/, YCS_G:''/*怀孕次数*/, YCS_P:''/*孕产史-分娩次数*/
        ,YCS_A:''/*孕产史-流产次数*/
        ,YQXY_SSY:''/*孕前血压(收缩压)*/,YQXY_SZY:''/*孕前血压（舒张压）*/
        ,YQTZ:'' /*孕期体重KG*/ ,SG:''/*身高*/
        ,DQXY_SSY:''/*当前血压(收缩压)*/ ,DQXY_SZY:''/*当前血压(舒张压)*/
        ,DQTZ:''/*当前体重*/
      },
      jbsxx:{//疾病史信息
        JZS:''/*家族史*/,  JWS:''/*既往史*/,  GRS:''/*个人史*/
        ,YCQK:''/*异常情况*/, FKSS:''/*妇科手术*/
      },
      rules: {
        csrq: [{
          required: true, message: '日期格式不正确,正确格式为:2014-01-01'
          , validator: this.csrqValidator
        }],
        xm: [{required: true, message: '姓名是必填项'}],
        zjh: [{required: true, message: '证件号是必填项'}],
        tel: [{
          required: true, message: '无效的手机号'
          , validator: this.telValidator
        }],
        gzdw: [{required: false, message: '工作单位是必填项'}],
        hkxz: [{required: true, message: '户口性质是必填项'}],
        xzz: [{required: true, message: '现住址是必填项'}]
      },
      sheetVisible: false,
      selectedList: 'zjlx',
      selected: "tab-jbxx",
      selectlist: {
        zjlxlist: zjlx,
      },
      xzzselectdatas: [
        {
          text: '山东省',
          value: '370000',
          children: [
            // { text: '济南市', value: '330100',
            //   children:[
            //     { text: '历下区', value: '330101' },
            //     { text: '历城区', value: '330202' },
            //
            // ]
            // },
            // { text: '青岛市', value: '330200' },

          ],
        },
        {
          text: '北京市',
          value: '110000',
          children: [],
        },
        {
          text: '天津市',
          value: '120000',
          children: [],
        },
        {
          text: '河北省',
          value: '130000',
          children: [],
        },
        {
          text: '山西省',
          value: '140000',
          children: [],
        },

        {
          text: '辽宁省',
          value: '210000',
          children: [],
        },
        {
          text: '吉林省',
          value: '220000',
          children: [],
        },
        {
          text: '黑龙省',
          value: '230000',
          children: [],
        },
        {
          text: '上海市',
          value: '310000',
          children: [],
        },
        {
          text: '江苏省',
          value: '320000',
          children: [],
        },

        {
          text: '浙江省',
          value: '330000',
          children: [],
        },


      ],
    }
  },
  created() {
  },
  methods: {
    onXzzChange({text, value, selectedOptions}) {
      let that = this;
      let nLen = selectedOptions.length;
      let obj = selectedOptions[nLen - 1];

      console.log(text, value, JSON.stringify(obj))

      let nType = that.getToar(obj.value, obj.text)
      let reqParam = {BM: obj.value, MC: obj.text, JB: nType}

      Indicator.open({text: '加载...', spinnerType: 'fading-circle'})

      let hisurl = '/api/womanrpt_htfy/YYT_Dict_Area'
      this.$axios.post(hisurl, reqParam).then((res) => {
        let code = res.data.code;
        let areaList = res.data;

        console.log(areaList)
        if (code === '10001') {
          for (let item in areaList) {
            if (nType != "5")//村
              obj.children.push({text: item.MC, value: item.BM, children: []})
            else
              obj.children.push({text: item.MC, value: item.BM})
          }
          Indicator.close()
        } else {
          Toast('行政区域选择失败:' + res.data.msg);
        }
      }).catch(err => {
        Indicator.close()
        Toast(JSON.stringify(err));
      }).finally(() => {
        Indicator.close()
      })


      //  let nType=this.getToar(obj.value,obj.text);

      /*
      if (!obj.children) {
        this.xzzselectshow=false
        this.jbxx.XZZ = selectedOptions.map((option) => option.text).join('/');
      }
*/

    },
    onXzzFinish({selectedOptions}) {
      this.xzzselectshow = false;
      this.jbxx.XZZ = selectedOptions.map((option) => option.text).join('/');
    },
    csrqValidator(val) {
      //日期的正则表达式
      var reg = /^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
      var regExp = new RegExp(reg);
      return regExp.test(val);
    },
    telValidator(val) {
      var reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      var regExp = new RegExp(reg);
      return regExp.test(val);
    },
    zjlxclick(listtype) {
      this.selectedList = listtype;

      if (listtype == 'zjlx') {
        this.selectlist.zjlxlist = zjlx;

      } else if (listtype == 'mz') {
        this.selectlist.zjlxlist = nation;
        //profession,education
      } else if (listtype == 'zy') {
        this.selectlist.zjlxlist = profession;

      } else if (listtype == 'xl') {
        this.selectlist.zjlxlist = education;
      }
      Toast(listtype)
      console.log('zjlxclick')
      this.sheetVisible = true
    },
    zjlxlistSelected(e) {
      console.log(JSON.stringify(e))

      if (this.selectedList == 'zjlx') {
        this.jbxx.ZJLX_W = e.name
        this.jbxx.ZJLXDM_W = e.id

      } else if (this.selectedList == 'mz') {
        this.jbxx.MZ = e.name
      } else if (this.selectedList == 'zy') {
        this.jbxx.ZY = e.name
      } else if (this.selectedList == 'xl') {
        this.jbxx.XL = e.name
      }
      this.sheetVisible = false;

    },

    //获取行政区类型
    getToar(val, txt) {
      /*Type of Administrative Region
      * 区域级别：
      1：省级       2：市级      3：区县级
      4：乡镇级      5：村级      * */

      let tmpVal = val.replace(/(0+)$/g,'');
      let tmpStr = txt;//

      if (tmpVal.length == 2) {
        return "1";
      } else if (tmpStr.search("市") != -1) {
        return "2";
      } else if (
          tmpStr.search("区") != -1
          || tmpStr.search("县") != -1
      ) {
        return "3";
      } else if (tmpStr.search("乡") != -1
          || tmpStr.search("镇") != -1
          || tmpStr.search("街") != -1

      ) {
        return "4";
      } else if (
          tmpStr.search("村") != -1
          ||tmpStr.search("社区") != -1
      ) {
        return "5";
      }
    }
  }
}
</script>

<style scoped>
.content {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.mint-header {
  font-size: 18px;
  background-color: #eb89a9;
}
</style>